export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":true},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Arimo&family=Dancing+Script&family=IBM+Plex+Sans+Thai&family=Noto+Sans+Thai:wght@400;500;700&family=Ovo&family=Poppins&family=Prompt:ital@0;1&family=Sriracha&display=swap"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"src":"https://kit.fontawesome.com/dcadb9a5ea.js","crossorigin":"anonymous","type":"text/javascript"},{"src":"https://firebasestorage.googleapis.com/v0/b/photowishv2.appspot.com/o/assets%2Fjs%2Fkonva9316.min.js?alt=media&token=56f9b90f-1b79-4477-9443-41ede451cdcb"},{"src":"https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"}],"noscript":[],"title":"PhotoWish - Present ","viewport":"width=device-width, initial-scale=1","charset":"utf-8"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000